<template>
  <TrainingAnswerPageBackground
    :items="getItems()"
    @continueTraining="continueTraining"
    :trainingID="trainingID"
  >
    <b-row class="training-score-text-top">
      <b-col>
        <span>
          恭喜你完成<span class="training-knowledge">
            {{ $route.query.sectionName }}
          </span>
          <span class="training-difficulty"
            >{{ result.finishDifficultyTagId }}年级</span
          >
          难度的攻关
        </span>
      </b-col>
    </b-row>
    <b-row class="training-score-text">
      <b-col>
        <span>
          获得训练答题<pybWord :pybNum="result.answerReward"></pybWord
          >星级奖励<span
            ><pybWord :pybNum="result.starReward"></pybWord>，共计<span>
              <pybWord
                :pybNum="result.answerReward + result.starReward"
              ></pybWord></span
            >，已纳入你的后勤补给!</span
          ></span
        >
      </b-col>
    </b-row>
    <b-row class="training-score-text-middle">
      <b-col>
        <span>
          本次任务还剩<span class="green">{{ result.remainingQuantity }}</span
          >个训练模块等待攻克，继续加油！</span
        >
      </b-col>
    </b-row>
    <b-row class="training-score-text-bottom">
      <b-col>
        <span
          >本次闯关质量为：<span
            v-for="item in result.star || 1"
            :key="item"
            class="iconfont icon-shoucang"
          ></span
        ></span>
      </b-col>
    </b-row>
  </TrainingAnswerPageBackground>
</template>

<script>
import TrainingAnswerPageBackground from "@/components/backgroung/training-answer-page-background.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import { getGroupFinish } from "@/api/english-big";
export default {
  data() {
    return {
      trainingID: "沙漠行动",
      result: "",
    };
  },
  components: {
    TrainingAnswerPageBackground,
    pybWord,
  },
  mounted() {
    this.finishPage();
  },
  methods: {
    finishPage() {
      const parmeter = { summaryId: this.$route.query.summaryId };
      getGroupFinish(parmeter).then((res) => {
        console.log("完成页", res);
        this.result = res.data.result;
      });
    },
    getItems() {
      return [this.result];
    },
    backTraining() {
      this.$router.replace({
        name: "BigModuleTrainingBase",
        query: {
          // module: 2,
        },
      });
    },
    continueTraining() {
      if (this.$route.query.isAllFinish) {
        this.$router.replace({
          name: "BigModuleStartEffectEvaluation",
          query: {
            // module: 2,
          },
        });
      } else {
        this.backTraining();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.training-score-text-top {
  margin-top: 30px;
}
.training-score-text {
  margin-top: 10px;
}
.training-score-text-middle {
  margin-top: 20px;
}
.training-score-text-bottom {
  margin-top: 50px;
}
.training-knowledge,
.green {
  color: $text-green;
  font-size: 20px;
  display: inline-block;
  font-weight: bold;
  padding: 0 4px;
}
.icon-shoucang {
  color: #ffc13b;
}
</style>