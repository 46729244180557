<template>
  <b-container class="training-back-content">
    <b-row class="training-content">
      <b-col sm="7" cols="12" class="training-left">
        <b-row class="training-left-top">
          <b-col>
            <EndCanvas
              :score="items[0].finishRate | turnPercentage"
              :totalScore="100"
              :canvasId="listeningId"
              :fillingColor="`#2cb774`"
            >
              <b-row>{{ trainingID }}</b-row>
              <b-row
                ><b-col class="training-progress"
                  ><span>{{ items[0].finishRate | turnPercentage }}</span
                  >%</b-col
                ></b-row
              >
            </EndCanvas>
          </b-col>
        </b-row>
        <b-row class="training-left-middle">
          <b-col>
            <slot></slot>
          </b-col>
        </b-row>
        <b-row align-h="center" class="training-left-bottom">
          <b-col cols="3" sm="3">
            <GlobalButton
              buttonType="round"
              buttonText="继续训练"
              class="big-end-form-btn"
              @afterClick="continueTraining"
            ></GlobalButton>
          </b-col>
          <!-- <b-col cols="3" sm="3" class="big-end-form-btn-right">
            <span @click="backTraining">回训练基地</span>
          </b-col> -->
        </b-row>
      </b-col>
      <b-col sm="5" class="training-right">
        <GlobalButton
          @afterClick="backHome"
          buttonType="iconline"
          icon="icon-fanhui"
          class="back-home-button"
          buttonText="回大本营"
          buttonColor="black"
        ></GlobalButton>
        <div>
          <b-row class="text-left">
            <b-col>
              <div>
                本次答题
                <span class="big-text">{{ items[0].answerCount }} </span
                >道, 准确率
                <span id="popover-target-1" class="iconfont icon-tishi"></span>
                :
                <span class="big-text"
                  >{{ items[0].correctRate | turnPercentage }}%</span
                >
              </div>
              <b-popover
                target="popover-target-1"
                triggers="hover"
                placement="top"
              >
                准确率综合考虑了你的答题正确率和答题时间
              </b-popover>
            </b-col>
          </b-row>
          <b-row class="accuracy-card">
            <b-col>
              <b-table
                bordered
                :fields="fields"
                :items="items"
                head-variant="light"
                variant="success"
                thead-class="accuracy-thead"
              ></b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="answer-notes">
              <span class="iconfont icon-submit-fill"></span>
              <span
                class="answer-notes-content"
                @click="$router.replace({ name: 'EntryIndex' })"
                >查看答题记录</span
              >
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EndCanvas from "../english-end/end-canvas.vue";
import GlobalButton from "@/components/global-button/global-button-enter.vue";
export default {
  data() {
    return {
      listeningId: "Id",
      fields: [
        {
          key: "correctCount",
          label: "正确(道)",
        },
        {
          key: "wrongCount",
          label: "错误(道)",
        },
        {
          key: "answerMinutes",
          label: "用时(分钟)",
        },
      ],
    };
  },
  props: {
    items: {
      default: function () {
        return [{}];
      },
    },
    trainingID: {
      default: "",
    },
  },
  mounted() {
    console.log("items", this.items);
  },
  components: {
    EndCanvas,
    GlobalButton,
  },
  methods: {
    continueTraining() {
      this.$emit("continueTraining");
    },
    // backTraining() {
    //   this.$emit("backTraining");
    // },
    backHome() {
      this.$router.replace({
        name: "HomeIndex",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.training-back-content {
  background: white;
  // width: 128vw;
  // height: 680px;
}
.training-content {
  min-height: 680px;
}
.training-left {
  min-height: 100%;
  overflow: hidden;
  padding: 3% 2% 4%;
  // display: flex;
  // flex-direction: column;
  // justify-items: center;
  // justify-content: center;
}
.training-right {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  padding: 0% 2% 4%;
  display: flex;
  flex-direction: column;
  // justify-items: center;
  justify-content: center;
  border-left: 1px solid #e5e5e5;
}
.back-home-button {
  position: absolute;
  top: 2%;
  right: 1%;
}
.text-left {
  margin-bottom: 20px;
}
.big-text {
  font-size: 1.5rem;
  color: $text-yellow;
}
// .accuracy-card {
//   margin-bottom: 40px;
// }
.answer-notes {
  padding-top: 40px;
  font-size: 1.2rem;
  color: #666;
}
.answer-notes-content {
  text-decoration: underline;
  cursor: pointer;
}
.big-end-form-btn {
  margin-top: 10px;
}
// .big-end-form-btn-right {
//   margin-top: 20px;
//   span {
//     text-decoration: underline;
//     cursor: pointer;
//   }
// }
.training-left-bottom {
  margin-top: 50px;
}
.training-progress {
  color: $text-green;
  font-size: 1.5rem;
}
.training-left-top {
  padding: 3rem 0;
}
</style>

<style lang="scss">
.training-back-content {
  .accuracy-thead {
    th {
      background-color: #2cb774 !important;
      color: #fff !important;
    }
  }
}
</style>